// routes
import { paths } from "src/routes/paths";
// config
import { PATH_AFTER_LOGIN } from "src/config-global";
// components
import Iconify from "src/components/iconify";

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: "Home",
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: "/",
  },
  {
    title: "Dictionaries",
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: paths.dictionaries,
  },
];
