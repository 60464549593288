import { lazy } from "react";
import { Outlet } from "react-router-dom";
// layouts
import MainLayout from "src/layouts/main";

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import("src/pages/home"));
const Page500 = lazy(() => import("src/pages/500"));
const Page403 = lazy(() => import("src/pages/403"));
const Page404 = lazy(() => import("src/pages/404"));
const ComingSoonPage = lazy(() => import("src/pages/coming-soon"));
const MaintenancePage = lazy(() => import("src/pages/maintenance"));
const DictionaryPage = lazy(() => import("src/pages/dictionary/index"));
const ListPage = lazy(() => import("src/pages/dictionary/word-list"));
const DictionaryLists = lazy(
  () => import("src/pages/dictionary/dictionary-lists")
);

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    children: [
      { path: "coming-soon", element: <ComingSoonPage /> },
      { path: "maintenance", element: <MaintenancePage /> },
      { path: "500", element: <Page500 /> },
      { path: "404", element: <Page404 /> },
      { path: "403", element: <Page403 /> },
      { path: "dictionaries", element: <DictionaryPage /> },
      { path: "list/:id", element: <ListPage /> },
      { path: "dictionary/manage-lists", element: <DictionaryLists /> },
    ],
  },
];
