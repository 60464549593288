import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TDictionary, TWord } from "src/types/dictionary";

interface IState {
  selectedDictionary: number | null;
  selectedDictionarySettings: TDictionary | null;
  selectedList: number | null;
  currentWord: {
    [key: number]: number;
  } | null;
  isReversedirection: boolean;
  wordLists: {
    [key: number]: TWord[];
  } | null;
}

const initialState: IState = {
  selectedDictionary: JSON.parse(
    localStorage.getItem("selectedDictionary") ?? "null"
  ),
  selectedDictionarySettings: JSON.parse(
    localStorage.getItem("selectedDictionarySettings") ?? "null"
  ),
  selectedList: JSON.parse(localStorage.getItem("selectedList") ?? "null"),
  currentWord: JSON.parse(localStorage.getItem("currentWord") ?? "null"),
  isReversedirection: JSON.parse(
    localStorage.getItem("isReversedirection") ?? "false"
  ),
  wordLists: JSON.parse(localStorage.getItem("wordLists") ?? "null"),
};

export const dictionarySlice = createSlice({
  name: "dictionary",
  initialState,
  reducers: {
    setDictonary: (state, action: PayloadAction<TDictionary>) => {
      const cashedDictionaryId =
        localStorage.getItem("selectedDictionary") ?? "null";
      if (+cashedDictionaryId !== action.payload.id) {
        state.selectedDictionary = action.payload.id;
        state.selectedDictionarySettings = action.payload;
        state.selectedList = null;
        localStorage.setItem("selectedDictionary", action.payload.id + "");
        localStorage.setItem(
          "selectedDictionarySettings",
          JSON.stringify(action.payload)
        );
        localStorage.setItem("selectedList", "null");
      } else {
        state.selectedDictionary = action.payload.id;
        state.selectedDictionarySettings = action.payload;
        localStorage.setItem("selectedDictionary", action.payload.id + "");
        localStorage.setItem(
          "selectedDictionarySettings",
          JSON.stringify(action.payload)
        );
      }
    },
    setList: (state, action: PayloadAction<number>) => {
      if (state.selectedList !== action.payload) {
        state.selectedList = action.payload;
        localStorage.setItem("selectedList", action.payload + "");
      }
    },
    setCurentWord: (
      state,
      action: PayloadAction<{ listId: number; wordIndex: number }>
    ) => {
      state.currentWord = {
        ...state.currentWord,
        [action.payload.listId]: action.payload.wordIndex,
      };
      localStorage.setItem(
        "currentWord",
        JSON.stringify({
          ...state.currentWord,
          [action.payload.listId]: action.payload.wordIndex,
        })
      );
    },
    setReversedirection: (state, action: PayloadAction<boolean>) => {
      state.isReversedirection = action.payload;
      localStorage.setItem("isReversedirection", action.payload + "");
    },
    setWordLists: (
      state,
      action: PayloadAction<{ listId: number; words: TWord[] }>
    ) => {
      state.wordLists = {
        ...state.wordLists,
        [action.payload.listId]: [...action.payload.words],
      };
      localStorage.setItem(
        "wordLists",
        JSON.stringify({
          ...state.wordLists,
          [action.payload.listId]: [...action.payload.words],
        })
      );
    },
  },
});

export const dictionaryActions = dictionarySlice.actions;
export const dictionaryReducer = dictionarySlice.reducer;
