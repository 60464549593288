import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Country, Language } from 'src/types/geo';

export const geoApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers, { getState }: { getState: () => any }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getState().auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  keepUnusedDataFor: 24 * 60 * 60,
  endpoints: (build) => ({
    getLanguages: build.query<Language[], void>({
      query: () => ({
        url: `/geo/languges/`,
      }),
    }),
    getCountries: build.query<Country[], void>({
      query: () => ({
        url: `/geo/countries/`,
      }),
    }),
  }),
});

export const { useGetLanguagesQuery, useGetCountriesQuery } = geoApi;
