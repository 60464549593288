import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "./auth/auth.api";
import { authReducer } from "./auth/auth.slice";
import { dictionaryApi } from "./dictionary/api";
import { geoApi } from "./dictionary/geo";
import { dictionarySlice } from "./dictionary/slice";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    auth: authReducer,
    [dictionaryApi.reducerPath]: dictionaryApi.reducer,
    [geoApi.reducerPath]: geoApi.reducer,
    dictionary: dictionarySlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      dictionaryApi.middleware,
      geoApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
