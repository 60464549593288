const ROOT = {
  DICTIONARIES: "/dictionaries",
};

export const paths = {
  auth: {
    register: "/register",
    login: "/login",
  },
  maintenance: "/maintenance",
  comingSoon: "/coming-soon",
  "403": "/403",
  "404": "/404",
  "500": "/500",
  dictionaries: ROOT.DICTIONARIES,
  list: (id: number) => `/list/${id}`,
  manageLists: "/dictionary/manage-lists",
};
